<template>
    <div class="shopping-paid">
        <p class="sp-result"><i class="el-icon-success"></i>支付成功</p>
        <div class="sp-amount" v-if="amount">&yen; {{amount}} 元</div>

        <div class="sp-return">
            <div style="margin-bottom: 5px;">{{min}} 秒后自动关闭</div>
            <el-button size="small" @click="Close" style="width: 80px;"> 关 闭 </el-button>
        </div>
    </div>
</template>
<script>
import {getOrder} from "@/api/user" 

export default {
    name: 'Paid',
    components: {},
    data() {
        return {
            amount: null,
            min: 3,
            timer: null
        }
    },
    created(){
        if(this.$route.query.total_amount) this.amount = this.$route.query.total_amount
        let _this = this
        this.timer = setInterval(()=>{
            if(_this.min == 1) _this.Close()
            else _this.min -- 
        },1000)
    },
    beforeDestroy(){
        if(this.timer) clearInterval(this.timer)
    },
    methods:{
        init(){
            getOrder({}).then(res=>{
                if(res.success) this.amount = res.result.records[0]
            })
        },
        Close(){
            this.$router.push('/shoppingCenter')
        }
    }
}
</script>
<style lang="scss" scoped>
.shopping-paid{
    text-align: center;
}
.sp-result{
    margin-top: 200px;
    font-size: 20px;
    i{
        margin-right: 10px;
        font-size: 40px;
        color:#2ca42c;
        position: relative;
        top: 8px;
    }
}

.sp-amount{
    font-size: 30px;
    color: #ed4e4e;
    font-weight: 600;
    margin-top: 20px;
}

.sp-return{
    margin-top: 100px;
}

</style>
<style>
</style>